@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
}
@media (max-width: 640px) {
  body {
    overflow-x: hidden;
  }
}
.border-element {
  position: relative;
  width: 0;
  height: 0;

  top: -22rem;
  border-style: solid;
  border-width: 2rem 2rem 0 2rem;
  border-color: rgb(253, 186, 116) transparent transparent transparent;
  opacity: 1;
  transition: opacity 0.3s ease-out;
}

.scroll-animation {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
}

#about-us.scroll-animation,
#our-services.scroll-animation {
  opacity: 1;
  transform: translateY(0);
}
